import {
  IStrapiNewsListResponse,
  IStrapiNewsParams,
  IStrapiNewsResponse,
  IStrapiNewsTagResponse,
} from 'models/strapi/strapiNews.model';
import { IBasePagedResponse } from 'shared/models/baseApi.model';
import { AuthHelper } from 'utils/authHelper.util';

export class NewsApiService {
  public static async getNews(params: IStrapiNewsParams): Promise<IBasePagedResponse<IStrapiNewsListResponse>> {
    return await AuthHelper.fetch<IBasePagedResponse<IStrapiNewsListResponse>>('/api/v1/news/', {
      method: 'GET',
      params,
    }, true);
  }

  public static async getTags(): Promise<Array<IStrapiNewsTagResponse>> {
    return await AuthHelper.fetch<Array<IStrapiNewsTagResponse>>('/api/v1/news/tags/', {
      method: 'GET',
    }, true);
  }

  public static async getNewsById(id: string): Promise<IStrapiNewsResponse> {
    return await AuthHelper.fetch<IStrapiNewsResponse>(`/api/v1/news/${id}`, {
      method: 'GET',
    }, true);
  }
}

export const NEWS_SECTION_ALL_ID = -1;

export const NEWS_SECTION_ALL = {
  id: NEWS_SECTION_ALL_ID,
  label: 'Все категории',
};

export const NEWS_BREADCRUMBS = [
  {
    label: 'Главная',
    link: '/',
    disabledRoute: false,
  },
  {
    label: 'Новости',
    link: '/news/',
    disabledRoute: false,
  },
];

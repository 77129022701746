import { IStrapiNewsPreviewMediaAttributes, IStrapiNewsTagResponse } from 'models/strapi/strapiNews.model';
import WindowWidthManager from 'shared/services/windowWidth.service';
import { ProviderService } from 'shared/models/providerService.model';
import { ENewsComponent } from 'enums/news/newsComponent.enum';
import NewsTextQuotePart from 'components/news/parts/NewsTextQuotePart.vue';
import NewsTextPart from 'components/news/parts/NewsTextPart.vue';
import NewsMediaPart from 'components/news/parts/NewsMediaPart.vue';
import type { Component } from 'vue';
import { ShowdownService } from 'services/showdown.service';


export class NewsService extends ProviderService {
  protected static serviceName = 'newsService';

  private listDivClassName = 'mm-list';
  private listWrapperClassName = 'mm-list-wrapper';
  public isSmallMobileView;
  public isMobileView;
  public isTabletView;
  public dynamicComponentMap = new Map<ENewsComponent, Component>([
    [ENewsComponent.Quote, NewsTextQuotePart],
    [ENewsComponent.Text, NewsTextPart],
    [ENewsComponent.Media, NewsMediaPart],
  ]);

  constructor(
    private currentOriginUrl: string,
    {
      isSmallMobile,
      isMobile,
      isTablet,
    } = WindowWidthManager.getAdaptivaRefs(),
  ) {
    super();
    this.isSmallMobileView = isSmallMobile;
    this.isMobileView = isMobile;
    this.isTabletView = isTablet;
  }

  private getImageUrl(url: string | undefined) {
    return url ? `${this.currentOriginUrl}/api/v1/news/images${url}` : '';
  }

  public getImage(image: IStrapiNewsPreviewMediaAttributes | undefined): string | undefined {
    if (!image?.formats) {
      return this.getImageUrl(image?.url);
    }

    if (this.isSmallMobileView.value) {
      return this.getImageUrl(image?.formats?.thumbnail?.url || image?.url);
    }

    if (this.isMobileView.value) {
      return this.getImageUrl(image?.formats?.small?.url || image?.url);
    }

    if (this.isTabletView.value) {
      return this.getImageUrl(image?.formats?.medium?.url || image?.url);
    }

    return this.getImageUrl(image?.url);
  }

  public getDisplayedText(text: string | undefined) {
    const splittedParagraphs = text?.split('\n')?.filter((el) => !!el && el !== '\n');
    return (splittedParagraphs || [])?.map((paragraph) => this.setTextLinkTarget(ShowdownService.getText(paragraph)));
  }

  public mapNavigationTitles(titles: Array<IStrapiNewsTagResponse>): Array<IStrapiNewsTagResponse> {
    return titles?.map((title) => ({
      ...title,
      label: this.getNavigationTitleLabel(title.label),
    }))?.filter((el) => !!el.label);
  }

  private getNavigationTitleLabel(title: string): string {
    return new DOMParser()?.parseFromString(title, 'text/html')?.querySelector('h1')?.innerText || '';
  }

  public generateListWrapperElement(element: HTMLElement, listSelector: 'ul' | 'ol') {
    element?.querySelectorAll(listSelector)?.forEach((el) => el.parentElement?.classList.add(this.listDivClassName + `-${listSelector}`));
    
    const listDivs = element?.querySelectorAll(`.${this.listDivClassName + `-${listSelector}`}`);
 
    if (!listDivs.length) {
      return element;
    }
 
    const listDivsChildren: Array<Element> = [];
    listDivs.forEach((list) => listDivsChildren.push(list.children?.[0]?.children?.[0]));

    const listStartIndex = Array.prototype.indexOf.call(element.children, listDivs[0]);

    if (!listStartIndex) {
      return element;
    }

    const listWrapperElement = document.createElement(listSelector);
    listWrapperElement?.classList?.add(this.listWrapperClassName + `-${listSelector}`);
    listDivsChildren?.forEach((item) => {
      listWrapperElement?.appendChild(item);
    });

    element?.insertBefore(listWrapperElement, element?.children[listStartIndex]);

    listDivs?.forEach((list) => element?.removeChild(list));

    return element;
  }

  private setTextLinkTarget(item: string): string {
    const parsedItem = new DOMParser()?.parseFromString(item, 'text/html');
    const link = parsedItem?.querySelector('a');

    if (link) {
      link.setAttribute('target', '_blank');
    }

    return parsedItem?.documentElement?.outerHTML;
  }
}
